import React, { useState, useEffect, useContext } from "react";
import {
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Box,
    Button,
    TextField,
    Grid,
    Typography,
} from "@mui/material";
import CardContainer from "../CardContainer";
import { useUsersContext } from "../../contexts/Users/UsersContext";
import { FeedbackContext } from "../../contexts/FeedBack/FeedbackContext";
import { useAuthContext } from "../../contexts/Auth/AuthProvider";
import { useNavigate } from "react-router-dom";

export default function CreateUsersPopup({ handlerPopup }) {
    const { getApiToken } = useAuthContext();
    const { createUser } = useUsersContext();
    const { fetchRoles, roles } = useUsersContext();
    const { setFeedbackMessage } = useContext(FeedbackContext);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        name: "",
        username: "",
        email: "",
        psswd: "",
        role: "",
        token: getApiToken,
    });
    
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        fetchRoles();
    }, []);

    function valuesChangeHandler(event) {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    }

    async function handlerSubmit(e) {
        e.preventDefault();
        const attempt = await createUser(formData);
        handlerAttempt(attempt);
    }

    function handlerAttempt(attempt) {
        if (attempt.success) {
            setFeedbackMessage({type: "success", message: attempt.message});
            handlerPopup();
        } else {
            if (attempt.notify){
                navigate(`/Notificado`, {state: { message: attempt.message}})
            } else {
                setFeedbackMessage({ type: "error", message: attempt.message });
            }
        }
    }

    return (
        <Box height='100%' width='100%'>
            <CardContainer hideIcon>
                <form method='Post' onSubmit={handlerSubmit}>
                    <Grid borderRadius='16px'>
                        <Grid p='.5rem 2px'>
                            <Typography
                                variant='h4'
                                fontWeight='bold'
                                textAlign='center'
                                mb='1rem'
                            >
                                Criar Usuário
                            </Typography>
                        </Grid>
                        <Grid p='.5rem 2px'>
                            <TextField
                                id='input_name'
                                label='Nome completo'
                                required
                                name='name'
                                fullWidth
                                type='text'
                                placeholder='v3.10.1'
                                value={formData.name}
                                onChange={valuesChangeHandler}
                            />
                        </Grid>
                        <Grid p='.5rem 2px'>
                            <TextField
                                id='input_email'
                                label='Email'
                                required
                                name='email'
                                fullWidth
                                type='text'
                                placeholder='v3.10.1'
                                value={formData.email}
                                onChange={valuesChangeHandler}
                            />
                        </Grid>
                        <Grid p='.5rem 2px'>
                            <TextField
                                id='input_username'
                                label='Usuário'
                                required
                                name='username'
                                fullWidth
                                type='text'
                                placeholder='joao.silva'
                                value={formData.username}
                                helperText={
                                    "O username deve só pode conter letra, número e underline, sendo no mínimo 5 caracteres."
                                }
                                onChange={valuesChangeHandler}
                            />
                        </Grid>
                        <Grid p='.5rem 2px'>
                            <TextField
                                id='input_password'
                                label='Senha'
                                required
                                name='psswd'
                                fullWidth
                                type='password'
                                placeholder='*******'
                                value={formData.psswd}
                                helperText={
                                    "O A senha deve conter pelo menos 1 maiuscula, 1 minuscula, 1 numerico e 1 dos seguintes caracteres especiais"
                                }
                                onChange={valuesChangeHandler}
                            />
                        </Grid>
                        <Grid p='.5rem 2px'>
                            <FormControl fullWidth variant='outlined'>
                                <InputLabel id='select-doctype'>
                                    Função
                                </InputLabel>
                                <Select
                                    name='role'
                                    required
                                    labelId='select-doctype'
                                    id='listdocuments'
                                    value={formData.role}
                                    onChange={valuesChangeHandler}
                                    label='Função'
                                >
                                    {roles.map((doc) => (
                                        <MenuItem key={doc} value={doc}>
                                            {doc}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid
                            p='1rem 2px'
                            display='flex'
                            justifyContent='center'
                            gap={4}
                        >
                            <Button variant={"outlined"} onClick={handlerPopup}>
                                Cancelar
                            </Button>
                            <Button variant={"contained"} type='submit'>
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </CardContainer>
        </Box>
    );
}
