import React from 'react';
import MyRoutes from './Routes';



const App = () => {
  return (
    <MyRoutes />
  );
};

export default App;