import { CssBaseline } from "@mui/material";
import React, { useContext } from "react";
import { Alert, IconButton, Snackbar, Grid, Box } from "@mui/material";
import { FeedbackContext, useFeedbackContext } from "../../contexts/FeedBack/FeedbackContext";

export default function PageContainer({ children }) {
    const { feedbackMessage, showFeedbackMessage } = useContext(FeedbackContext);
    const { handleClose } = useFeedbackContext();

    return (
        <>
            <CssBaseline />
            <Grid
                container
                justifyContent='center'
                alignContent='top'
                sx={{
                    padding: { xs: "0", md: "0" },
                    minHeight: "100vh",
                    position: "relative",
                    backgroundColor: "#E7EDF3",
                }}
            >
                {children}
                {feedbackMessage.message ? (
                    <>
                        <Snackbar
                            open={showFeedbackMessage}
                            autoHideDuration={4000}
                            onClose={handleClose}
                        >
                            <Alert variant='filled' severity={feedbackMessage.type}>
                                <Box display="flex" alignItems="center">
                                    <Box flex="1">{feedbackMessage.message}</Box>
                                    <IconButton
                                    aria-label='close'
                                    color='inherit'
                                    size='small'
                                    onClick={handleClose}
                                    >
                                    x
                                    </IconButton>
                                </Box>
                            </Alert>
                        </Snackbar>
                    </>
                ) : (
                    ""
                )}
            </Grid>
        </>
    );
}
