// Home.js
import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import CardContainer from "../components/CardContainer";

const Home = () => {
    const links = [
        { text: 'Tela de Bem Vindo', to: '/BemVindo' },
        { text: 'Termo de Uso mais recente', to: '/TermosDeUso' },
        { text: 'Política de Privacidade mais recente', to: '/PoliticaDePrivacidade' },
        { text: 'Redefinição de Senha', to: '/RedefinirSenha'}
    ];
    return (
        <>
            <CardContainer hideIcon>
                <Grid container justifyContent='space-between' mb='2rem'>
                    <Grid item xs={12} md={10} mb='2rem'>
                        <Typography variant='h4' fontWeight='bold'>
                            Páginas Públicas
                        </Typography>
                        <Typography variant='body' textAlign='left'>
                                <p>
                                    Este sistema é direcionado a administração dos Termos de Uso e Política de Privacidade, 
                                    você não encontrará dados de usuários que utilizam o serviço Wi-Fí Público neste sistema.
                                </p>
                                <p>
                                    Para obter informações de usuários que utilizam o serviço de Wi-Fí Público, acesse a <a href="https://control.vilaainteligente.site:8443/" target="_blank" rel="noopener noreferrer">controladora</a>.
                                    
                                </p>
                        </Typography>
                    </Grid>
                    
                </Grid>
                <Grid item xs={12} md={4} mb='2rem'>
                    {links.map((link, index) => (
                        <li key={index}>
                        <a href={link.to} target="_blank" rel="noopener noreferrer">
                            {link.text}
                        </a>
                        </li>
                    ))}
                </Grid>

            </CardContainer>
        </>
    );
};

export default Home;
