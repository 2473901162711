import React, { useState, useEffect, useContext } from "react";
import api from "../../services/api";
import {
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Button,
    TextField,
    Grid,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/Auth/AuthProvider";
import CardContainer from "../CardContainer";
import { FeedbackContext } from "../../contexts/FeedBack/FeedbackContext";
import { useAttachmentContext } from "../../contexts/Attachment/AttachmentContext";
import ServerResponseContext from "../../contexts/ServerResponse/ServerResponseContext";

const CreateDocuments = ({ handlerPopup }) => {
    const { getApiToken } = useAuthContext();
    const { setFeedbackMessage } = useContext(FeedbackContext)
    const { setUpdateAttachments } = useAttachmentContext()
    const navigate = useNavigate();
    const [listLanguages, setListLanguages] = useState([]);
    const [documentLanguage, setDocumentLanguage] = useState("");
    const [listDocuments, setListDocuments] = useState([]);
    const [documentType, setDocumentType] = useState("");
    const [version, setVersion] = useState("");
    const [attachment, setAttachment] = useState(null);

    function cleanVariables() {
        setVersion("");
        setAttachment("");
        setDocumentLanguage("");
        setDocumentType("");
        document.getElementById("input_file").value = "";
    }
    function fetchData() {
        Promise.all([
            api.get("/languages"),
            api.get("/legaldocuments", {
                headers: {
                    Authorization: getApiToken(),
                },
            }),
        ])
            .then(function ([reslan, resld]) {
                setListLanguages(reslan.data);
                setListDocuments(resld.data);
                
            })
            .catch ((error) => {
                const attempt = ServerResponseContext(error)
                if (attempt.notify){
                    navigate(`/Notificado`, {state: { message: attempt.message}})
                } else {
                    setFeedbackMessage({ type: "error", message: attempt.message });
                }
            });
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const formData = new FormData();

        const formFields = {
            version: version,
            document_type: documentType,
            document_language: documentLanguage,
        };

        formData.append("data", JSON.stringify(formFields));
        formData.append("attachment", attachment);
        try {
            const response = await api.post("/createdocument/", formData, {
                headers: {
                    "Authorization": getApiToken(),
                    "Content-Type": "multipart/form-data",
                },
            })
            setFeedbackMessage({ type: "success", message: response.data.message });
            handlerPopup()
            setUpdateAttachments('att')
        } catch (error) {
            const message =  ServerResponseContext(error)
            if (message.notify){
                navigate(`/Notificado`, {state: { message: message.message}})
            } else {
                setFeedbackMessage({ type: "error", message: message.message });
            }
        }
    };

    const handleLanguageChange = (event) => {
        setDocumentLanguage(event.target.value);
    };

    const handleDocumentChange = (event) => {
        setDocumentType(event.target.value);
    };

    const handleFileChange = (event) => {
        setAttachment(event.target.files[0]);
    };

    return (
        <CardContainer hideIcon>
            <form method='Post' onSubmit={handleSubmit}>
                <Grid borderRadius='16px'>
                    <Grid p={2}>
                        <Typography
                            variant='h4'
                            fontWeight='bold'
                            textAlign='center'
                            mb='1rem'
                        >
                            Criar Termo de Uso/Privacidade
                        </Typography>
                    </Grid>
                    <Grid p={2}>
                        <FormControl fullWidth variant='outlined'>
                            <InputLabel id='select-language'>Idioma</InputLabel>
                            <Select
                                required
                                fullWidth
                                labelId='select-language'
                                id='listlanguages'
                                value={documentLanguage}
                                onChange={handleLanguageChange}
                                label='Idioma'
                            >
                                {listLanguages.map((language) => (
                                    <MenuItem key={language} value={language}>
                                        {language}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid p={2}>
                        <FormControl fullWidth variant='outlined'>
                            <InputLabel id='select-doctype'>
                                Tipo do Documento
                            </InputLabel>
                            <Select
                                required
                                labelId='select-doctype'
                                id='listdocuments'
                                value={documentType}
                                onChange={handleDocumentChange}
                                label='Tipo do Documento'
                            >
                                {listDocuments.map((doc) => (
                                    <MenuItem key={doc} value={doc}>
                                        {doc}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid p={2}>
                        <TextField
                            id='input_version'
                            label='Versão'
                            required
                            fullWidth
                            type='text'
                            placeholder='v3.10.1'
                            helperText='A versão deve ser formatada coforme v0.0.0 onde zero pode ser qualquer numérico.'
                            value={version}
                            onChange={(e) => {
                                setVersion(e.target.value);
                            }}
                        />
                    </Grid>
                    <Grid p={2}>
                        <TextField
                            id='input_file'
                            required
                            fullWidth
                            inputProps={{ accept: "application/pdf" }}
                            type='file'
                            onChange={handleFileChange}
                        />
                    </Grid>
                    <Grid p={2} display='flex' justifyContent='center' gap={4}>
                        <Button
                            variant={"outlined"}
                            onClick={() => {
                                cleanVariables();
                                handlerPopup();
                            }}
                        >
                            Cancelar
                        </Button>
                        <Button variant={"contained"} type='submit'>
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </CardContainer>
    );
};

export default CreateDocuments;
