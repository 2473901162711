import React from "react";
import Login from "./pages/Login";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AuthProvider from "./contexts/Auth/AuthProvider";
import ViewAttachment from "./pages/ViewAttachment";
import ResetPassword from "./pages/ResetPassword";
import ShowUseTerm from "./pages/ShowUseTerm";
import ShowPrivatePolice from "./pages/ShowPrivatePolice";
import RequestPasswordReset from "./pages/RequestPasswordReset";
import ShowNotifications from "./pages/ShowNotifications";
import { ThemeProvider } from "@mui/material";
import { mainTheme } from "./contexts/Theme/ThemeProvider";
import AuxResetPassword from "./pages/AuxResetPassword";
import AuxValidateEmail from "./pages/AuxValidateEmail";
import ValidateEmail from "./pages/ValidateEmail";
import Welcome from "./pages/Welcome";
import Users from "./pages/Users";
import PageContainer from "./components/PageContainer";
import FeedbackProvider from "./contexts/FeedBack/FeedbackContext";
import Header from "./pages/Header";
import Terms from "./pages/Terms";
import AuditUnifi from "./pages/AuditUnifi";
import AuditSystem from "./pages/AuditSystem";

export default function MyRoutes() {
    return (
        <Router>
            <FeedbackProvider>
                <PageContainer>
                    <ThemeProvider theme={mainTheme}>
                        <AuthProvider>
                            <Routes>
                                <Route exact path='/*' element={<Login />} />
                                <Route
                                    exact
                                    path='/RedefinirSenha'
                                    element={<RequestPasswordReset />}
                                />
                                <Route
                                    exact
                                    path='/Notificado'
                                    element={<ShowNotifications />}
                                />
                                <Route
                                    exact
                                    path='/ValidarEmail'
                                    element={<ValidateEmail />}
                                />
                                <Route
                                    exact
                                    path='/TermosDeUso'
                                    element={<ShowUseTerm />}
                                />
                                <Route
                                    exact
                                    path='/PoliticaDePrivacidade'
                                    element={<ShowPrivatePolice />}
                                />
                                <Route
                                    exact
                                    path='/reset-password/:token_type/:token'
                                    element={<AuxResetPassword />}
                                />
                                <Route
                                    exact
                                    path='/validar-email/:token_type/:token'
                                    element={<AuxValidateEmail />}
                                />
                                <Route
                                    exact
                                    path='/BemVindo'
                                    element={<Welcome />}
                                />
                                <Route
                                    exact
                                    path='/Usuarios'
                                    element={<Users />}
                                />
                                <Route
                                    exact
                                    path='/AlterarSenha/'
                                    element={<ResetPassword />}
                                />
                                <Route
                                    path='/home/*'
                                    element={<Header />}
                                />
                                <Route
                                    path='/terms/*'
                                    element={<Terms />}
                                />
                                <Route
                                    path='/unifi/*'
                                    element={<AuditUnifi />}
                                />
                                <Route
                                    path='/system/*'
                                    element={<AuditSystem />}
                                />

                                <Route
                                    exact
                                    path='home/terms/visualizarDocumento/:type/:language'
                                    element={<ViewAttachment />}
                                />
                            </Routes>
                        </AuthProvider>
                    </ThemeProvider>
                </PageContainer>
            </FeedbackProvider>
        </Router>
    );
}
