import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import CardContainer from "../components/CardContainer";

import {
    Box,
    Button,
    TextField,
    Typography,
    FormLabel,
} from "@mui/material";
 
import { useAuthContext } from "../contexts/Auth/AuthProvider";
import { FeedbackContext } from "../contexts/FeedBack/FeedbackContext";

const RequestPasswordReset = () => {
    const [username, setUsername] = useState("");
    const [confirmRequest, setConfirmRequest] = useState(false);
    const { requestPasswordReset } = useAuthContext();
    const navigate = useNavigate();
    const [invalidLogin] = useState(false);
    const { setFeedbackMessage } = useContext(FeedbackContext);
    const [email, setEmail] = useState("");



    async function handleSubmit(e) {
        e.preventDefault();
        const requestAttempt = await requestPasswordReset(username);
        handlerAttempt(requestAttempt)
    }

    function handlerAttempt(attempt) {
        if (attempt.success) {
            setEmail(attempt.message);
            setConfirmRequest(true);
        } else {
            if (attempt.notify){
                navigate(`/Notificado`, {state: { message: attempt.message}})
            } else {
                setFeedbackMessage({ type: "error", message: attempt.message });
            }
        }
    }

    return (
         <>
            
            <Box
                width='100%'
                height='100vh'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
            >
                <form method='Post' onSubmit={handleSubmit}>
                    <CardContainer>
                        <Box
                            display='flex'
                            flexDirection='column'
                            rowGap='10px'
                            borderRadius='16px'
                            textAlign='center'
                            width={{ lg: "30vw" }}
                            height={{ lg: "30vh" }}
                        >
                            <Typography
                                variant='h4'
                                fontWeight='bold'
                                textAlign='center'
                                mb='1rem'
                            >
                                {confirmRequest
                                    ? "Solicitação Realizada com successo"
                                    : "Solicitar Redefinição de senha"}
                            </Typography>

                            {confirmRequest ? (
                                <>
                                    <FormLabel
                                        display='flex'
                                        width='100%'
                                    >
                                        Para realizar a redefinição da senha, um
                                        link foi enviado para <b>{email}</b>. Esse
                                        link tem a <b>validade de 5 minutos</b>,
                                        após esse período, o link expirará e
                                        você terá de realizar uma nova
                                        solicitação.
                                    </FormLabel>
                                    <Box>
                                        <Button
                                            variant='contained'
                                            sx={{ marginTop: "1rem" }}
                                            onClick={() => navigate("../login")}
                                        >
                                            Voltar para o login
                                        </Button>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Typography variant='body' color='#245B8F'>
                                        Digite seu nome de usuário. Caso o
                                        usuário exista será enviado um link para
                                        o e-mail previamente cadastrado.
                                    </Typography>

                                    <TextField
                                        error={invalidLogin}
                                        sx={{ transition: "background-color 3s ease-in-out" }}
                                        value={username}
                                        onChange={(e) =>
                                            setUsername(e.target.value)
                                        }
                                        id='outlined-basic'
                                        label='Usuário'
                                        variant='outlined'
                                        fullWidth
                                        required
                                    />

                                    <Box
                                        justifyContent='center'
                                        display='flex'
                                        gap={1}
                                        mt={2}
                                    >
                                        <Button
                                            variant='outlined'
                                            type='reset'
                                            onClick={() => setUsername("")}
                                        >
                                            Limpar
                                        </Button>
                                        <Button
                                            variant='contained'
                                            type='submit'
                                        >
                                            Solicitar
                                        </Button>
                                    </Box>
                                </>
                            )}
                        </Box>
                    </CardContainer>
                </form>
            </Box>
         </>
    );
};

export default RequestPasswordReset;
