// Login.js
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    Box,
    Button,
    FormGroup,
    Switch,
    TextField,
    Typography,
} from "@mui/material";

import { useAuthContext } from "../contexts/Auth/AuthProvider";
import api from "../services/api";
import CardContainer from "../components/CardContainer";
import { FeedbackContext } from "../contexts/FeedBack/FeedbackContext";
import ServerResponseContext from "../contexts/ServerResponse/ServerResponseContext";

export default function ResetPassword() {
    const { updateToken } = useAuthContext();
    const [firstPassword, setFirstPassword] = useState("");
    const [seccondPassword, setSecondPassword] = useState("");
    const navigate = useNavigate();
    const { setFeedbackMessage } = useContext(FeedbackContext);

    // const [invalidPassword, setInvalidPassword] = useState(false);

    // function handleInvalidPassword() {
    //     setInvalidPassword(true);
    // }

    async function resetPassword(firsPass, secPass) {
        try {
            const response = await api
                .post("/reset-password", {
                    type: updateToken.token_type,
                    token: updateToken.token,
                    new_psswd: firsPass,
                    agn_psswd: secPass,
                });
            return { success: true, message: response.data.message };
        } catch (error) {
            return ServerResponseContext(error)
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();

        if (firstPassword !== seccondPassword)
            setFeedbackMessage({
                type: "error",
                message: "Senhas não correspondem",
            });
        else {
            const resetAttempt = await resetPassword(
                firstPassword,
                seccondPassword
            );
            if (resetAttempt.success) {
                setFeedbackMessage({
                    type: "success",
                    message: resetAttempt.message,
                });
                navigate("../login");
            } else
                setFeedbackMessage({
                    type: "error",
                    message: resetAttempt.message,
                });
        }
    }
    function handleCancel() {
        navigate("../login");
    }

    function showPassword(inputId, element) {
        const passInput = document.getElementById(inputId);
        element ? (passInput.type = "text") : (passInput.type = "password");
    }

    return (
        <>
            <Box
                width='100%'
                height='100vh'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems={"center"}
            >
                <form method='Post' onSubmit={handleSubmit}>
                    <CardContainer>
                        <Box
                            display='flex'
                            rowGap='20px'
                            flexDirection='column'
                            justifyContent={"center"}
                            borderRadius='16px'
                            minWidth='300px'
                            width='30vw'
                            maxWidth='500px'
                        >
                            <Typography
                                variant='h4'
                                fontWeight='bold'
                                textAlign='center'
                                mb='1rem'
                            >
                                Alterar Senha
                            </Typography>

                            <FormGroup>
                                <TextField
                                    // error={invalidPassword}
                                    sx={{ transition: "background-color 3s ease-in-out" }}
                                    value={firstPassword}
                                    onChange={(e) =>
                                        setFirstPassword(e.target.value)
                                    }
                                    id='firstInput'
                                    label='Nova Senha'
                                    variant='outlined'
                                    type='password'
                                    fullWidth
                                    required
                                />
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    textAlign='right'
                                >
                                    Exibir Senha
                                    <Switch
                                        size='small'
                                        onChange={(event, value) =>
                                            showPassword("firstInput", value)
                                        }
                                        color='secondary'
                                    />
                                </Typography>
                            </FormGroup>

                            <FormGroup>
                                <TextField
                                    id='secondInput'
                                    // error={invalidPassword}
                                    value={seccondPassword}
                                    onChange={(e) =>
                                        setSecondPassword(e.target.value)
                                    }
                                    type='password'
                                    label='Repita a Senha'
                                    variant='outlined'
                                    fullWidth
                                    required
                                />
                                <Typography
                                    variant='body2'
                                    color='primary'
                                    textAlign='right'
                                >
                                    Exibir Senha
                                    <Switch
                                        size='small'
                                        onChange={(event, value) =>
                                            showPassword("secondInput", value)
                                        }
                                        color='secondary'
                                    />
                                </Typography>
                            </FormGroup>
                            <Box justifyContent='center' display='flex' gap={2}>
                                <Button
                                    variant='outlined'
                                    type='reset'
                                    onClick={() => handleCancel()}
                                >
                                    Cancelar
                                </Button>
                                <Button variant='contained' type='submit'>
                                    Alterar senha
                                </Button>
                            </Box>
                        </Box>
                    </CardContainer>
                </form>
            </Box>
        </>
    );
}
