// Terms.js
import React, { useState } from "react";
import {
    Grid,
    Typography,
    Box,
} from "@mui/material";
import CreateDocuments from "../components/CreateDocuments";
import ListAttachments from "../components/ListAttachments";
import CardContainer from "../components/CardContainer";

const Terms = () => {
    const [showPopup, setShowPopup] = useState(true);
    //const [feedbackMessage, setFeedbackMessage] = useState({});

    function handlerCreateDocumentsPopup() {
        setShowPopup(!showPopup);
    }

    return (
        <>
            <Grid
                display={showPopup ? "none" : ""}
                container
                position={"absolute"}
                top={0}
                left={0}
                justifyContent='center'
                alignContent={"center"}
                bgcolor={"rgba(36,91,143,.5)"}
                width='100%'
                height='100%'
                zIndex={2}
            >
                <Box minWidth='30vw'>
                    <CreateDocuments
                        handlerPopup={handlerCreateDocumentsPopup}
                        showPopup={showPopup}
                    />
                </Box>
            </Grid>

            <CardContainer hideIcon>
                <Grid container justifyContent='space-between' mb='2rem'>
                    <Grid item>
                        <Typography variant='h4' fontWeight='bold'>
                            Termos de Uso e Política de Privacidade
                        </Typography>
                    </Grid>
                </Grid>

                <ListAttachments
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    //setFeedbackMessage={setFeedbackMessage}
                />
            </CardContainer>
        </>
    );
};

export default Terms;
