import { useContext, useState } from "react";
import { AuthContext } from "./AuthContext";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import ServerResponseContext  from "../ServerResponse/ServerResponseContext";

const AuthProvider = ({ children }) => {
    const [token, setToken] = useState();
    const [apiToken, setApiToken] = useState();
    const [updateToken, setUpdateToken] = useState({});

    return (
        <AuthContext.Provider
            value={{
                token,
                setToken,
                apiToken,
                setApiToken,
                updateToken,
                setUpdateToken,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export function useAuthContext() {
    const {
        token,
        setToken,
        apiToken,
        setApiToken,
        updateToken,
        setUpdateToken,
    } = useContext(AuthContext);
    const navigate = useNavigate();

    async function doLogin(username, password, recap) {
        try {
            const response = await api.post("/login",{
                username: username,
                psswd: password,
                recaptcha: recap
            },{});
            setToken(response.data);
            setApiToken(response.data.token_type + " " + response.data.access_token);
            localStorage.setItem( "token", JSON.stringify(response.data));
            return { success: true, message: "Login realizado com successo.", notify: false};
        } catch (error) {
            return ServerResponseContext(error)
        }
    }

    async function requestPasswordReset(username) {
        try {
            const requestReset = await api.post("/request-reset/", {
                username: username,
            });
            return { success: true, message: requestReset.data.message};
        } catch (error) {
            return ServerResponseContext(error)
        }
    }

    function doLogout() {
        setToken("");
        setApiToken("");
        localStorage.setItem("token", JSON.stringify(" "));
        navigate("/login");
    }

    function getApiToken(token) {
        const storageToken = JSON.parse(localStorage.getItem("token"));
        return apiToken
            ? apiToken
            : storageToken.token_type + " " + storageToken.access_token;
    }


    return {
        doLogin,
        doLogout,
        getApiToken,
        requestPasswordReset,
        token,
        setToken,
        setApiToken,
        updateToken,
        setUpdateToken,
    };
}

export default AuthProvider;
