
function ServerResponseContext(res){
    try {
        if (res.code === "ERR_BAD_REQUEST") {
            return { success: false, message: res.response.data.detail, notify: false };
        } else if (res.response.status === 403) {
            return { success: false, message: res.response.data.detail, notify: true };
        } else if (res.response.status === 401) {
            return { success: false, message: res.response.data.detail, notify: true };
        } else if (res.code === "ERR_NETWORK") {
            return { success: false, message: "Servidor Offline, tente mais tarde!", notify: true};
        } 
    } catch  {
        const mess = res.response?.data || "Erro interno, por favor tente novamente mais tarde!";
        return { success: false, message: mess, notify: false };    
    }


}


export default ServerResponseContext;