import { createContext, useContext, useEffect, useState } from "react";

export const FeedbackContext = createContext();

export default function FeedbackProvider({ children }) {
    const [feedbackMessage, setFeedbackMessage] = useState({
        type: "",
        message: "",
    });
    const [showFeedbackMessage, setShowFeedbackMessage] = useState(false);

    
    useEffect(() => {
        setFeedbackMessage(feedbackMessage);
        if (feedbackMessage){
            setShowFeedbackMessage(true)
        }
    }, [showFeedbackMessage, feedbackMessage]);

    return (
        <FeedbackContext.Provider
            value={{
                feedbackMessage,
                setFeedbackMessage,
                showFeedbackMessage,
                setShowFeedbackMessage,
            }}
        >
            {children}
        </FeedbackContext.Provider>
    );
}

export function useFeedbackContext() {
    const { setShowFeedbackMessage, setFeedbackMessage } = useContext(FeedbackContext);

    const handleClose = (event, reason) => {
        setShowFeedbackMessage(false);
        setFeedbackMessage({type: null,message: null})
        
    };
    return { handleClose };
}
