import { createContext, useContext, useState } from "react";
import api from "../../services/api";
import { useAuthContext } from "../Auth/AuthProvider";
import ServerResponseContext from "../ServerResponse/ServerResponseContext";
import { useNavigate } from "react-router-dom";
import { FeedbackContext } from "../../contexts/FeedBack/FeedbackContext";
const UserContext = createContext({});

export default function UsersProvider({ children }) {
    const [users, setUsers] = useState([]);
    const [screenUsers, setScreenUsers] = useState([]);
    const [updateUsers, setUpdateUsers] = useState();
    const [roles, setRoles] = useState([]);
 
    return (
        <UserContext.Provider
            value={{
                users,
                setUsers,
                updateUsers,
                setUpdateUsers,
                screenUsers,
                setScreenUsers,
                roles,
                setRoles,
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export function useUsersContext() {
    const { getApiToken } = useAuthContext();
    const navigate = useNavigate();
    const { setFeedbackMessage } = useContext(FeedbackContext);

    const {
        users,
        setUsers,
        updateUsers,
        setUpdateUsers,
        screenUsers,
        setScreenUsers,
        roles,
        setRoles,
    } = useContext(UserContext);

    async function fetchUsers() {
        Promise.all([
            api.get("/users", {
                headers: {
                    Authorization: getApiToken(),
                },
            }),
        ])
            .then(function ([reslan]) {
                setUsers(reslan.data);
                setScreenUsers(reslan.data);
            })
            .catch ((error) => {
                const attempt = ServerResponseContext(error)
                if (attempt.notify){
                    navigate(`/Notificado`, {state: { message: attempt.message}})
                } else {
                    setFeedbackMessage({ type: "error", message: attempt.message });
                }
            });
    }
    async function createUser(data) {
        try {
            const response = await api.post("/createuser", data, {
                headers: {
                    Authorization: getApiToken(),
                },
            });

            return {
                success: true,
                message: response.data.message,
            };
        } catch (error) {
            return ServerResponseContext(error)
        }
    }

    async function changePassword(firsPass, secPass) {
        try {
            const attemptChange = await api
                .post("/change-password", {
                    new_psswd: firsPass,
                    agn_psswd: secPass,
                },
                {
                    headers: {
                        Authorization: getApiToken(),
                    },
                })
                return { success: true, message: attemptChange.data.message };        
        } catch (error) {
            return ServerResponseContext(error)
        }
    }

    async function fetchRoles() {
        Promise.all([
            api.get("/roles", {
                headers: {
                    Authorization: getApiToken(),
                },
            }),
        ])
            .then(function ([reslan]) {
                setRoles(reslan.data);
            })
            .catch ((error) => {
                const attempt = ServerResponseContext(error)
                if (attempt.notify){
                    navigate(`/Notificado`, {state: { message: attempt.message}})
                } else {
                    setFeedbackMessage({ type: "error", message: attempt.message });
                }
            });
    }

    function filterUsers(filter) {
        setScreenUsers(users.filter((user) => user.name.indexOf(filter) > -1));
    }

    function clearFilter() {
        setScreenUsers(users);
    }

    return {
        fetchUsers,
        fetchRoles,
        createUser,
        filterUsers,
        clearFilter,
        changePassword,
        updateUsers,
        screenUsers,
        roles,
        setUpdateUsers,
    };
}
