import React, { useEffect, useState } from "react";
import CardContainer from "../CardContainer";
import { Box, Button, ButtonGroup, Grid, Typography } from "@mui/material";
import api from "../../services/api";

export default function ShowCurrentAttach({ documentType, title }) {
    const [selectedLang, setSelectedLang] = useState("pt_BR");
    const [pdf, setPDF] = useState("");

    async function fetchData() {
        try {
            const response = await api.get(`/${documentType}/${selectedLang}`, {
                responseType: "blob",
            });
            
            const blob = response.data;
            const objectUrl = URL.createObjectURL(blob);
            setPDF(objectUrl);
        } catch (error) {
            setPDF("")
        }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        fetchData();
    }, [selectedLang]);

    return (
        <>
            <Grid
                position='absolute'
                bottom='10px'
                width='100%'
                justifyContent='center'
                display='flex'
            >
                <CardContainer hideIcon>
                    <Typography
                        textAlign='center'
                        variant='h5'
                        fontWeight='bold'
                        mb='.5rem'
                    >
                        Selecione o Idioma
                    </Typography>
                    <Grid
                        alignItems='center'
                        justifyContent='center'
                        display='flex'
                        width='100%'
                    >
                        <ButtonGroup
                            variant='contained'
                            aria-label='outlined primary button group'
                        >
                            <Button onClick={() => setSelectedLang("pt_BR")}>
                                pt-br
                            </Button>
                            <Button onClick={() => setSelectedLang("es_ES")}>
                                es-es
                            </Button>
                            <Button onClick={() => setSelectedLang("en_US")}>
                                en-un
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </CardContainer>
            </Grid>
            {pdf ? (
                <Box width='100vw' height='100vh'>
                    <embed
                        src={pdf}
                        type='application/pdf'
                        width='100%'
                        height='100%'
                    />
                </Box>
            ) : (
                <>
                    <Typography variant='h6' textAlign='center'>
                        Documento em fase de construção!
                    </Typography>
                </>
            )}
        </>
    );
}
