import { useNavigate, useLocation } from "react-router-dom";
import CardContainer from "../components/CardContainer";

import {
    Box,
    Button,
    Typography,
    FormLabel,
} from "@mui/material";
 
const ShowNotifications = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { message } = location.state;

    async function handleSubmit(e) {
        e.preventDefault();
    }

    return (
         <>
            
            <Box
                width='100%'
                height='100vh'
                display='flex'
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
            >
                <form method='Post' onSubmit={handleSubmit}>
                    <CardContainer>
                        <Box
                            display='flex'
                            flexDirection='column'
                            rowGap='10px'
                            borderRadius='16px'
                            textAlign='center'
                            width={{ lg: "30vw" }}
                            height={{ lg: "30vh" }}
                        >
                            <Typography
                                variant='h4'
                                fontWeight='bold'
                                textAlign='center'
                                mb='1rem'
                            >
                                Aviso !!!
                            </Typography>
                            <>
                                <FormLabel
                                    display='flex'
                                    width='100%'
                                >
                                    {message}
                                </FormLabel>
                                <Box>
                                    <Button
                                        variant='contained'
                                        sx={{ marginTop: "1rem" }}
                                        onClick={() => navigate("../login")}
                                    >
                                        Voltar para o login
                                    </Button>
                                </Box>
                            </>
                            
                        </Box>
                    </CardContainer>
                </form>
            </Box>
         </>
    );
};

export default ShowNotifications;
