import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useAuthContext } from "../../contexts/Auth/AuthProvider";
import { Grid, Link } from "@mui/material";

export default function WifiMenu() {
    const { doLogout } = useAuthContext();

    return (
        <AppBar
            position='static'
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: '2rem'
            }}
            
        >
            <Grid
                container
                width='100%'
                justifyContent='center'
                columnSpacing={2}
            >
                <Grid item xs={10} lg={10}>
                    <Toolbar
                        sx={{
                            width: "100%",
                            justifyContent: "space-between",
                            padding: "-24px",
                        }}
                    >
                        <Box display='flex' gap={3}>
                            <Link
                                href='/home'
                                variant='body1'
                                color='#fff'
                                underline='none'
                            >
                                <Typography
                                    variant='h6'
                                    component='div'
                                    sx={{ flexGrow: 1 }}
                                >
                                    Home
                                </Typography>
                            </Link>
                            <Link
                                href='/home/users'
                                variant='body1'
                                color='#fff'
                                underline='none'
                            >
                                <Typography
                                    variant='h6'
                                    component='div'
                                    sx={{ flexGrow: 1 }}
                                >
                                    Usuários
                                </Typography>
                            </Link>
                            <Link
                                href='/home/terms'
                                variant='body1'
                                color='#fff'
                                underline='none'
                            >
                                <Typography
                                    variant='h6'
                                    component='div'
                                    sx={{ flexGrow: 1 }}
                                >
                                    Termos
                                </Typography>
                            </Link>
                            <Link
                                href='/home/unifi'
                                variant='body1'
                                color='#fff'
                                underline='none'
                            >
                                <Typography
                                    variant='h6'
                                    component='div'
                                    sx={{ flexGrow: 1 }}
                                >
                                    Unifi
                                </Typography>
                            </Link>
                            <Link
                                href='/home/system'
                                variant='body1'
                                color='#fff'
                                underline='none'
                            >
                                <Typography
                                    variant='h6'
                                    component='div'
                                    sx={{ flexGrow: 1 }}
                                >
                                    Sistema
                                </Typography>
                            </Link>
                        </Box>
                        <Button color='inherit' onClick={doLogout}>
                            Sair
                        </Button>
                    </Toolbar>
                </Grid>
            </Grid>
        </AppBar>
    );
}
