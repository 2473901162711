import React from 'react'
import ShowCurrentAttach from '../components/ShowCurrentAttach'

export default function ShowUseTerm() {
  return (
     <>
        <ShowCurrentAttach title={"Termos De Uso"} documentType="use-term" />
     </>
  )
}
