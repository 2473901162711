import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useUsersContext } from "../../contexts/Users/UsersContext";

export default function UserList() {
    const { fetchUsers, screenUsers, updateUsers } = useUsersContext();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        fetchUsers();
    }, [updateUsers]);

    return (
        <>
            {screenUsers ? (
                <TableContainer sx={{ border: "1px solid #dad9d9", borderRadius: "5px" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        ID
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Nome
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Username
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Email
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Função
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Ativo?
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Data de Criação
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Data de Alteração
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Data de Exclusão
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {screenUsers.map((user) => (
                                <TableRow key={user.id}>
                                    <TableCell>{user.id}</TableCell>
                                    <TableCell>{user.name}</TableCell>
                                    <TableCell>{user.username}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.role}</TableCell>
                                    <TableCell>{user.is_active}</TableCell>
                                    <TableCell>{user.created_on}</TableCell>
                                    <TableCell>{user.updated_on}</TableCell>
                                    <TableCell>{user.deleted_on}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                "Nenhum usuário cadastrado"
            )}
        </>
    );
}
