import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "../services/api";
import { Box, Typography } from "@mui/material";

import { useAuthContext } from "../contexts/Auth/AuthProvider";
import ServerResponseContext from "../contexts/ServerResponse/ServerResponseContext";

export default function ViewAttachment() {
    const [pdf, setPDF] = useState("");

    const { getApiToken } = useAuthContext();
    const { type, language } = useParams();
    const [erroMessage, setErroMessage] = useState("");
    async function fetchData() {
        try {
            const response = await api.get(`/${type}/${language}`, {
                headers: {
                    Authorization: getApiToken(),
                },
                responseType: "blob",
            });

            const blob = response.data;
            const objectUrl = URL.createObjectURL(blob);
            setPDF(objectUrl);
        } catch (error) {
            const res = ServerResponseContext(error)
            setErroMessage(res);
        }
    }
    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        fetchData();
    }, []);

    return (
        <>
            {pdf ? (
                <Box width='100vw' height='99vh'>
                    <embed
                        src={pdf}
                        type='application/pdf'
                        width='100%'
                        height='100%'
                    />
                </Box>
            ) : (
                <>
                    <Typography variant='h3' textAlign='center'>
                        {erroMessage}
                    </Typography>
                </>
            )}
        </>
    );
}
