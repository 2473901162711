import React, { useState, useContext } from "react";
import {
    Box,
    Button,
    TextField,
    Grid,
    Typography,
    Switch
} from "@mui/material";
import CardContainer from "../CardContainer";
import { FeedbackContext } from "../../contexts/FeedBack/FeedbackContext";
import { useUsersContext } from "../../contexts/Users/UsersContext";
import { useNavigate } from "react-router-dom";

export default function ChangePsswdPopup({ handlerPopup }) {
    const [firstPassword, setFirstPassword] = useState("");
    const [seccondPassword, setSecondPassword] = useState("");
    const { setFeedbackMessage } = useContext(FeedbackContext);
    // const [invalidPassword, setInvalidPassword] = useState(false);
    const { changePassword } = useUsersContext();
    const navigate = useNavigate();

    async function handlerSubmit(e) {
        e.preventDefault();
        if (firstPassword !== seccondPassword)
            setFeedbackMessage({
                type: "error",
                message: "Senhas não correspondem",
            });
        else {
            const resetAttempt = await changePassword(
                firstPassword,
                seccondPassword
            );
            handlerAttempt(resetAttempt);
        }
    }

    function handlerAttempt(attempt) {
        if (attempt.success) {
            setFeedbackMessage({type: "success", message: attempt.message});
            handlerPopup();
        } else {
            if (attempt.notify){
                navigate(`/Notificado`, {state: { message: attempt.message}})
            } else {
                setFeedbackMessage({ type: "error", message: attempt.message });
            }

        }

    }

    function showPassword(inputId, element) {
        const passInput = document.getElementById(inputId);
        element ? (passInput.type = "text") : (passInput.type = "password");
    }

    return (
        <Box height='100%' width='100%'>
            <CardContainer hideIcon>
                <form method='Post' onSubmit={handlerSubmit}>
                    <Grid borderRadius='16px'>
                        <Grid p='.5rem 2px'>
                            <Typography
                                variant='h4'
                                fontWeight='bold'
                                textAlign='center'
                                mb='1rem'
                            >
                                Alterar Minha Senha
                            </Typography>
                        </Grid>
                        <Grid p='.5rem 2px'>
                            <TextField
                                // error={invalidPassword}
                                sx={{ transition: "background-color 3s ease-in-out" }}
                                value={firstPassword}
                                onChange={(e) =>
                                    setFirstPassword(e.target.value)
                                }
                                id='firstInput'
                                label='Nova Senha'
                                variant='outlined'
                                type='password'
                                fullWidth
                                required
                            />
                            <Typography
                                    variant='body2'
                                    color='primary'
                                    textAlign='right'
                            >
                                    Exibir Senha
                                    <Switch
                                        size='small'
                                        onChange={(event, value) =>
                                            showPassword("firstInput", value)
                                        }
                                        color='secondary'
                                    />
                            </Typography>
                        </Grid>
                        <Grid p='.5rem 2px'>
                            <TextField
                                    id='secondInput'
                                //     error={invalidPassword}
                                    value={seccondPassword}
                                    onChange={(e) =>
                                        setSecondPassword(e.target.value)
                                    }
                                    type='password'
                                    label='Repita a Senha'
                                    variant='outlined'
                                    fullWidth
                                    required
                            />
                            <Typography
                                variant='body2'
                                color='primary'
                                textAlign='right'
                            >
                                Exibir Senha
                                <Switch
                                    size='small'
                                    onChange={(event, value) =>
                                        showPassword("secondInput", value)
                                    }
                                    color='secondary'
                                />
                            </Typography>
                        </Grid>
                        <Grid
                            p='1rem 2px'
                            display='flex'
                            justifyContent='center'
                            gap={4}
                        >
                            <Button variant={"outlined"} onClick={handlerPopup}>
                                Cancelar
                            </Button>
                            <Button variant={"contained"} type='submit'>
                                Salvar
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </CardContainer>
        </Box>
    );
}
