
import ReCAPTCHA from "react-google-recaptcha";

function Recaptcha({onRecaptchaChange}) {

  const handleRecaptchaChange = (value) => {
    onRecaptchaChange(value);
  }
  const sitkey = process.env.REACT_APP_SITE_KEY


  return (
    <div>
      {/* <form onSubmit={handleSubmit}> */}
        <ReCAPTCHA
          sitekey={sitkey}
          onChange={handleRecaptchaChange}
        />
      {/* </form> */}
    </div>
  );
}

export default Recaptcha;