import { createTheme } from "@mui/material/styles";

export const mainTheme = createTheme({
    palette: {
        primary: {
            main: "#245B8F",
        },
        secondary: {
            main: "#368974",
        },
        text: {
            primary: "#021e33",
        },
    },
    typography: {
        MuiFormLabel: {
            color: "#245B8F",
        },
        h4: {
            fontSize: '1.6rem',
            color: "#245B8F",
            fontWeight: 'bolder'
        },
        button: {
            outline: {
                fontWeight: "bolder",
            },
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                outlined: {
                    border: `2px solid #368974`, 
                    color: '#368974',
                    fontWeight: "600",
                },
                contained: {
                    backgroundColor: '#368974',
                    fontWeight: "bold",

                },
                root: {
                    fontSize: "1.4rem",
                    height: 50,
                    textTransform: 'none'
                    
                },
            },
        },
        MuiFormControlLabel: {
            root:{
                color: '#245B8F'
            }
        },
        MuiFormLabel: {
            root:{
                color: '#245B8F'
            }
        },
    },
});
