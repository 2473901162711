// Users.js
import React, { useState } from "react";
import { Button, Grid, Typography, Box, TextField } from "@mui/material";
import CardContainer from "../components/CardContainer";
import { useUsersContext } from "../contexts/Users/UsersContext";
import UserList from "../components/ListUsers";
import CreateUsersPopup from "../components/CreateUsersPopup";
import ChangePsswdPopup from "../components/ChangePsswdPopup";

export default function Users() {
    const { filterUsers, clearFilter, setUpdateUsers, updateUsers } = useUsersContext();
    const [showPopupCreateUser, setShowPopupCreateUser] = useState(false);
    const [filter, setFilter] = useState("");
    const [showPopupChangePsswd, setShowPopupChangePsswd] = useState(false);

    function handlerFilter() {
        filterUsers(filter);
    }

    function handlerPopup() {
        setShowPopupCreateUser(!showPopupCreateUser);
        setUpdateUsers(!updateUsers);
    }

    function handlerPopup2() {
        setShowPopupChangePsswd(!showPopupChangePsswd);
    }

    return (
        <>
            <CardContainer hideIcon>
                <Grid container justifyContent='space-between' mb='2rem'>
                    <Grid item xs={12} md={4} mb='2rem'>
                        <Typography variant='h4' fontWeight='bold'>
                            Usuários
                        </Typography>
                        
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        md={2}
                        justifyContent='end'
                    >
                         <Button
                            variant='contained'
                            fullWidth
                            sx={{
                                fontSize: "0.9114rem",
                                height: "54px",
                                backgroundColor: "#8f7f24",
                            }}
                            onClick={handlerPopup2}
                        >
                            Alterar Minha Senha
                        </Button>
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        md={12}
                        justifyContent='end'
                        alignItems='end'
                    >
                        <Grid
                            item
                            container
                            xs={12}
                            md={8}
                            alignItems='end'
                            gap={1}
                        >
                            <Grid item xs={12} md={6}>
                                <TextField
                                    id='input_filterName'
                                    label='Pesquisar por nome'
                                    required
                                    name='name'
                                    fullWidth
                                    type='text'
                                    value={filter}
                                    onChange={(e) => setFilter(e.target.value)}
                                    placeholder='v3.10.1'
                                />
                            </Grid>
                            <Grid
                                item
                                display='flex'
                                gap={1}
                                xs={5}
                                md={2}
                                lg={2}
                            >
                                <Button
                                    variant='contained'
                                    onClick={handlerFilter}
                                    sx={{
                                        height: "54px",
                                        minWidth: "100px",
                                    }}
                                >
                                    Filtrar
                                </Button>
                                <Button
                                    variant='outlined'
                                    onClick={clearFilter}
                                    sx={{
                                        height: "54px",
                                        border: "2px solid #E0E0E0",
                                        color: "#757575",
                                        fontSize: "1.114rem",
                                        minWidth: "100px",
                                    }}
                                >
                                    Limpar
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            md={4}
                            justifyContent='end'
                        >
                            <Button
                                variant='contained'
                                fullWidth
                                sx={{
                                    fontSize: "1.114rem",
                                    height: "54px",
                                    backgroundColor: "#245B8F",
                                }}
                                onClick={handlerPopup}
                            >
                                Adicionar Usuário
                            </Button>
                        </Grid>                            
                    </Grid>
                </Grid>

                <UserList />

                <Box
                    display={showPopupCreateUser ? "flex" : "none"}
                    justifyContent='center'
                    alignItems='center'
                    position='absolute'
                    bgcolor='rgba(36,91,143,.5)'
                    width='100%'
                    height='103.1vh'
                    top={0}
                    left={0}
                >
                    <Box width='40%' maxWidth='600px' minWidth='300px'>
                        <CreateUsersPopup handlerPopup={handlerPopup} />
                    </Box>
                </Box>

                <Box
                    display={showPopupChangePsswd ? "flex" : "none"}
                    justifyContent='center'
                    alignItems='center'
                    position='absolute'
                    bgcolor='rgba(36,91,143,.5)'
                    width='100%'
                    height='103.1vh'
                    top={0}
                    left={0}
                >
                    <Box width='40%' maxWidth='600px' minWidth='300px'>
                        <ChangePsswdPopup handlerPopup={handlerPopup2} />
                    </Box>
                </Box>
            </CardContainer>
        </>
    );
}
