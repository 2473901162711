import React, { useEffect, useState } from "react";
import { useAttachmentContext } from "../../contexts/Attachment/AttachmentContext";
import {
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";

export default function ListAttachments({
    showPopup,
    setShowPopup,
}) {
    const {
        attachments,
        fetchAttachments,
        filterAttachments,
        documentsType,
        languages,
        updateAttachments,
    } = useAttachmentContext();

    const [selectedLanguage, setSelectedLanguage] = useState("");
    const [selectedDocumentType, setSelectedDocumentType] = useState("");
    const [screenAttachment, setScreenAttachment] = useState([]);

    function handlerCreateDocumentsPopup() {
        setShowPopup(!showPopup);
    }
    function handlerFilter(documentType, language) {
        setScreenAttachment(filterAttachments(documentType, language));
    }

    function clearFiltersOnClick() {
        setScreenAttachment(attachments);
        setSelectedDocumentType("");
        setSelectedLanguage("");
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        fetchAttachments();
    }, [updateAttachments]);

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setScreenAttachment(attachments);
    }, [attachments]);

    return (
        <>
            <Grid
                container
                mb='2rem'
                columnSpacing={2}
                alignItems='end'
                justifyContent='space-between'
            >
                <Grid item container lg={6} columnSpacing={2} alignItems='end'>
                    <Grid item xs={12} md={8} lg={6}>
                        <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>
                                Tipo do Documento
                            </InputLabel>
                            <Select
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={selectedDocumentType ?? " "}
                                label='Tipo do Documento'
                                onChange={(e) => {
                                    setSelectedDocumentType(e.target.value);
                                    handlerFilter(
                                        e.target.value,
                                        selectedLanguage
                                    );
                                }}
                            >
                                {documentsType.map((type) => (
                                    <MenuItem
                                        key={type}
                                        defaultValue=''
                                        value={type ?? " "}
                                    >
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={7} md={6} lg={4}>
                        <FormControl fullWidth>
                            <InputLabel id='demo-simple-select-label'>
                                Idioma
                            </InputLabel>
                            <Select
                                labelId='demo-simple-select-label'
                                id='demo-simple-select'
                                value={selectedLanguage ?? " "}
                                label='Idioma'
                                onChange={(e) => {
                                    setSelectedLanguage(e.target.value);
                                    handlerFilter(
                                        selectedDocumentType,
                                        e.target.value
                                    );
                                }}
                            >
                                {languages.map((language) => (
                                    <MenuItem
                                        key={language}
                                        defaultValue=' '
                                        value={language ?? " "}
                                    >
                                        {language}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5} md={4} lg={2}>
                        <Button
                            variant='outlined'
                            sx={{
                                height: "54px",
                                border: "2px solid #E0E0E0",
                                color: "#757575",
                                fontSize: "1.114rem",
                            }}
                            onClick={() => {
                                clearFiltersOnClick();
                            }}
                        >
                            Limpar
                        </Button>
                    </Grid>
                </Grid>
                <Grid item lg={5}>
                    <Button
                        variant='contained'
                        fullWidth
                        onClick={handlerCreateDocumentsPopup}
                        sx={{
                            fontSize: "1.114rem",
                            height: "54px",
                            backgroundColor: "#245B8F",
                        }}
                    >
                        Adicionar Termo de Uso/Política de Privacidade
                    </Button>
                </Grid>
            </Grid>
            {attachments.length > 0 ? (
                <TableContainer
                    sx={{ border: "1px solid #dad9d9", borderRadius: "5px" }}
                >
                    <Table>
                        <TableHead sx={{ borderRadius: "10px" }}>
                            <TableRow sx={{ borderRadius: "10px" }}>
                                <TableCell align='left'>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Documento
                                    </Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Idioma
                                    </Typography>{" "}
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Versão
                                    </Typography>
                                </TableCell>
                                <TableCell align='left'>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Ações
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {screenAttachment.map((attachment) => (
                                <TableRow
                                    key={attachment.id}
                                    sx={{
                                        "&:last-child td, &:last-child th": {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell align='left'>
                                        {attachment.system_documenttype}
                                    </TableCell>
                                    <TableCell component='th' scope='row'>
                                        {attachment.document_language}
                                    </TableCell>
                                    <TableCell align='left'>
                                        {attachment.version}
                                    </TableCell>

                                    <TableCell align='left'>
                                        <Button
                                            props={{ target: "_blank" }}
                                            sx={{
                                                color: "#2A8974",
                                                fontSize: "1.114rem",
                                            }}
                                            variant='outline'
                                            target="_blank"
                                            href={`terms/visualizarDocumento/attachment/${attachment.id}`}
                                        >
                                            Visualizar
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Typography textAlign='center'>
                    Nenhum documento adicionado
                </Typography>
            )}
        </>
    );
}
