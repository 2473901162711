import React from "react";
 
import ShowCurrentAttach from "../components/ShowCurrentAttach";

export default function ShowPrivatePolice() {
    return (
         <>
            <ShowCurrentAttach
                title={"Politica de Privacidade"}
                documentType='private-policy'
            />
         </>
    );
}
