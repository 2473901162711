// Login.js
import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CardContainer from "../components/CardContainer";
import {
    Box,
    Button,
    FormGroup,
    TextField,
    Typography,
    Link,
    Switch,
} from "@mui/material";

import { useAuthContext } from "../contexts/Auth/AuthProvider";
import { FeedbackContext } from "../contexts/FeedBack/FeedbackContext";
import Recaptcha from "../components/Recaptcha";
import api from "../services/api";
const packageJson = require('../../package.json');

export default function Login() {
    const { doLogin } = useAuthContext();
    const { setFeedbackMessage } = useContext(FeedbackContext);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [recaptchaValue, setRecaptchaValue] = useState("");
    const navigate = useNavigate();
    const [invalidLogin, setInvalidLogin] = useState(false);
    const [apiVersion, setApiVersion] = useState("0");
    const [webVersion, setWebVersion] = useState("0");

    async function fetchData() {
        const response = await api.get('/version-api',);
        setApiVersion(response.data);
        setWebVersion(packageJson.version);
    }

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        fetchData();
    }, []);
    
    function cleanLogin() {
        setPassword("");
        setUsername("");
        setRecaptchaValue("");
    }

    const handleRecaptchaChange = (newValue) => {
        setRecaptchaValue(newValue);
    }

    

    function showPassword(inputId, element) {
        const passInput = document.getElementById(inputId);
        element ? (passInput.type = "text") : (passInput.type = "password");
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const loginAttempt = await doLogin(username, password, recaptchaValue);
        handlerAttempt(loginAttempt)
    }

    function handlerAttempt(attempt) {
        if (attempt.success) {
            setFeedbackMessage({type: "success", message: "Login realizado com sucesso!"});
            navigate(`/home`)
        } else {
            if (attempt.notify){
                navigate(`/Notificado`, {state: { message: attempt.message}})
            } else {
                setFeedbackMessage({ type: "error", message: attempt.message });
                setInvalidLogin(true);
            }

        }
    }

    return (
        <>
            <>
                <Box
                    width='100%'
                    height='100vh'
                    display='flex'
                    flexDirection='column'
                    justifyContent='center'
                    alignItems={"center"}
                >
                    <form method='Post' onSubmit={handleSubmit}>
                        <CardContainer>
                            <Box
                                display='flex'
                                rowGap='20px'
                                flexDirection='column'
                                justifyContent={"space-around"}
                                borderRadius='16px'
                                minWidth='300px'
                                width='30vw'
                                maxWidth='500px'
                                height='40vh'
                            >
                                <Typography
                                    variant='h4'
                                    fontWeight='bold'
                                    textAlign='center'
                                    mb='1rem'
                                >
                                    Wifi-Publico
                                </Typography>

                                <TextField
                                    onFocus={() => setInvalidLogin(false)}
                                    error={invalidLogin}
                                    sx={{ transition: "background-color 3s ease-in-out" }}
                                    value={username}
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                    id='outlined-basic'
                                    label='Usuário'
                                    variant='outlined'
                                    fullWidth
                                    required
                                />

                                <FormGroup>
                                    <TextField
                                        onFocus={() => setInvalidLogin(false)}
                                        id='input_password'
                                        error={invalidLogin}
                                        value={password}
                                        onChange={(e) =>
                                            setPassword(e.target.value)
                                        }
                                        type='password'
                                        label='Senha'
                                        variant='outlined'
                                        fullWidth
                                        required
                                    />
                                    <Typography
                                        variant='body2'
                                        color='primary'
                                        textAlign='right'
                                    >
                                        Exibir Senha
                                        <Switch
                                            size='small'
                                            onChange={(event, value) =>
                                                showPassword(
                                                    "input_password",
                                                    value
                                                )
                                            }
                                            color='secondary'
                                        />
                                    </Typography>
                                    <Typography textAlign='center'>
                                        <Link
                                            href='/RedefinirSenha'
                                            underline='none'
                                        >
                                            Esqueceu a senha?
                                        </Link>
                                    </Typography>
                                </FormGroup> 
                                <Recaptcha onRecaptchaChange={handleRecaptchaChange} />
                                <Box
                                    justifyContent='center'
                                    display='flex'
                                    gap={1}
                                >
                                    <Button
                                        variant='outlined'
                                        color='secondary'
                                        onClick={cleanLogin}
                                        type='reset'
                                    >
                                        Limpar
                                    </Button>
                                    <Button variant='contained' type='submit'>
                                        Logar
                                    </Button>
                                </Box>
                            </Box>
                            
                        </CardContainer>
                        <Box height="fit-content" width="100%" display="flex" justifyContent="space-around" marginTop="30px" marginBottom="30px">
                            {apiVersion && <Typography fontSize={14}>{`API: v${apiVersion}`}</Typography>}
                            {webVersion && <Typography fontSize={14}>{`WEB: v${webVersion}`}</Typography>}
                        </Box>
                    </form>
                    
                </Box>
                
            </>
            
        </>
    );
}
