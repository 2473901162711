import { createContext, useContext, useState } from "react";
import api from "../../services/api";
import { useAuthContext } from "../Auth/AuthProvider";
import { useNavigate } from "react-router-dom";
import { FeedbackContext } from "../../contexts/FeedBack/FeedbackContext";
import ServerResponseContext  from "../ServerResponse/ServerResponseContext";

const AttachmentContext = createContext({});

export default function AttachmentProvider({ children }) {
    const [attachments, setAttachments] = useState([]);
    const [updateAttachments, setUpdateAttachments] = useState();
    const [languages, setLanguages] = useState([]);
    const [documentsType, setDocumentsTypes] = useState([]);

    return (
        <AttachmentContext.Provider
            value={{
                attachments,
                setAttachments,
                languages,
                setLanguages,
                documentsType,
                setDocumentsTypes,
                updateAttachments,
                setUpdateAttachments,
            }}
        >
            {children}
        </AttachmentContext.Provider>
    );
}

export function useAttachmentContext() {
    const { getApiToken } = useAuthContext();
    const navigate = useNavigate();
    const { setFeedbackMessage } = useContext(FeedbackContext);
    const {
        attachments,
        setAttachments,
        documentsType,
        setDocumentsTypes,
        languages,
        setLanguages,
        updateAttachments,
        setUpdateAttachments
    } = useContext(AttachmentContext);

    async function fetchAttachments() {
        Promise.all([
            api.get("/attachments", {
                headers: {
                    Authorization: getApiToken(),
                },
            }),
        ])
            .then(function ([reslan]) {
                setAttachments(reslan.data);

                let fetchDocumentsTypes = reslan.data.map(
                    (attachment) => attachment.system_documenttype
                );
                setDocumentsTypes([...new Set(fetchDocumentsTypes)]);

                let fetchLanguages = reslan.data.map(
                    (attachment) => attachment.document_language
                );
                setLanguages([...new Set(fetchLanguages)]);
                return reslan.data;
            })
            .catch ((error) => {
                const attempt = ServerResponseContext(error)
                if (attempt.notify){
                    navigate(`/Notificado`, {state: { message: attempt.message}})
                } else {
                    setFeedbackMessage({ type: "error", message: attempt.message });
                }
            });
    }

    function filterAttachments(documentType, language) {
        return attachments.filter(
            (attachment) =>
                (language === "" ||
                    attachment.document_language === language) &&
                (documentType === "" ||
                    attachment.system_documenttype === documentType)
        );
    }

    return {
        fetchAttachments,
        filterAttachments,
        attachments,
        documentsType,
        languages,
        updateAttachments,
        setUpdateAttachments
    };
}
