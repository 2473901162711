// AuditUnifi.js
import { Grid, Typography } from "@mui/material";
import CardContainer from "../components/CardContainer";
import UnifiList from "../components/ListUnifi";

export default function AuditUnifi() {
    return (
        <>
            <CardContainer hideIcon>
                <Grid container justifyContent='space-between' mb='2rem'>
                    <Grid item xs={12} md={8} mb='2rem'>
                        <Typography variant='h4' fontWeight='bold'>
                            Dados de Auditoria Controladora Ubiquiti
                        </Typography>
                        
                    </Grid>
                    <Typography variant='body' textAlign='left'>
                                <p>
                                    Para atender a LGPD, o sistema possui uma rotina que
                                    identifica e excluí usuários que estão inativos a mais de 180 dias.
                                    A Identificação do usuário é feita pelo MAC.
                                    A rotina que executa essa ação está programada para acontecer toda terça-feira as 03:00AM.
                                </p>
                                <p>
                                    Nenhum dado de usuário é salvo neste sistema, todos os dados coletados de usuário são salvos
                                    pela controladora da ubiquiti.
                                    Contudo é possível acessar a controladora via API e solicitar para executar a ação
                                    forgot_client no qual é passado uma lista de MACs, e a mesma que providência a exclusão.
                                </p>
                        </Typography>
                </Grid>
                <UnifiList />
            </CardContainer>
        </>
    );
}
