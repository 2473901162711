import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthContext } from "../contexts/Auth/AuthProvider";

export default function AuxResetPassword() {
    const { token_type, token } = useParams();
    const { setUpdateToken,  } = useAuthContext();
    const navigate = useNavigate();

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        setUpdateToken({ token_type: token_type, token: token });
        navigate("/AlterarSenha");
    }, []);
    return <div>Redirecionando...</div>;
}
