import { Route, Routes } from "react-router-dom";
import WifiMenu from "../components/Header";
import Home from "./Home";
import Users from "./Users";
import Terms from "./Terms";
import AttachmentProvider from "../contexts/Attachment/AttachmentContext";
import { Grid } from "@mui/material";
import UsersProvider from "../contexts/Users/UsersContext";
import AuditUnifi from "./AuditUnifi";
import AuditSystem from "./AuditSystem";

export default function Header() {
    return (
        <>
            <Grid item xs={12} lg={12} >
                <WifiMenu />
            </Grid>
            <Grid item xs={10} lg={10} minHeight='80vh'  >
                <Routes>
                    <Route
                        path='/'
                        element={
                            <>
                                <Home />
                            </>
                        }
                    />
                    <Route
                        path='users'
                        element={
                            <UsersProvider>
                                <Users />
                            </UsersProvider>
                        }
                    />
                    <Route
                        path="terms"
                        element={
                            <>
                                <AttachmentProvider>
                                    <Terms />
                                </AttachmentProvider>
                            </>
                        }
                    />
                    <Route
                        path="unifi"
                        element={
                            <>
                                <AttachmentProvider>
                                    <AuditUnifi />
                                </AttachmentProvider>
                            </>
                        }
                    />
                    <Route
                        path="system"
                        element={
                            <>
                                <AttachmentProvider>
                                    <AuditSystem />
                                </AttachmentProvider>
                            </>
                        }
                    />
                </Routes>
            </Grid>
        </>
    );
}
