import { useTheme } from "@emotion/react";
import { Box } from "@mui/material";
import React from "react";

export default function CardContainer({ children, hideIcon,...props }) {
    const theme = useTheme();
    return (
        <Box
            border={`5px solid  ${theme.palette.primary}`}
            bgcolor='#fff'
            borderTop={`5px solid ${theme.palette.primary.main}`}
            borderColor={theme.palette.text.seccondary}
            borderRadius='10px'
            justifyContent='center'
            height='auto'
            alignItems='center'
            p='29px 32px 49px 32px '
        >
            <Box display={hideIcon? "none":"flex"} mb="2rem" justifyContent='center'>
                <img src='./MainIcon.png' alt="A imagem contêm a logo da iniciativa Vila A Inteligente associado ao serviço prestado Wifi-Público."/>
            </Box>
            {children}
        </Box>
    );
}
