// Login.js
import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import ServerResponseContext from "../contexts/ServerResponse/ServerResponseContext";
import {
    Box,
    Button,
    Typography,
    FormLabel,
} from "@mui/material";

import { useAuthContext } from "../contexts/Auth/AuthProvider";
import api from "../services/api";
import CardContainer from "../components/CardContainer";

export default function ValidateEmail() {
    const { updateToken } = useAuthContext();
    const navigate = useNavigate();
    const [resultado, setResultado] = useState(null);

    const validEmail = useCallback(async () => {
        try {
          const response = await api.post("/validateemail",{type: updateToken.token_type, token: updateToken.token},{});
          setResultado(response.data.message);
        } catch (error) {
            const res = ServerResponseContext(error)
            setResultado(res);
        }
    }, [updateToken.token, updateToken.token_type]);
  
    useEffect(() => {
        validEmail();
      }, [validEmail]); 

    return (
        <>
           <Box
               width='100%'
               height='100vh'
               display='flex'
               flexDirection='column'
               justifyContent='center'
               alignItems='center'
           >
                <CardContainer>
                    <Box
                        display='flex'
                        flexDirection='column'
                        rowGap='10px'
                        borderRadius='16px'
                        textAlign='center'
                        width={{ lg: "30vw" }}
                        height={{ lg: "30vh" }}
                    >
                        <Typography
                            variant='h4'
                            fontWeight='bold'
                            textAlign='center'
                            mb='1rem'
                        >
                            Validação de E-mail
                        </Typography>
                        <>
                            <FormLabel
                                display='flex'
                                width='100%'
                            >
                                {resultado}
                            </FormLabel>
                            <Box>
                                <Button
                                    variant='contained'
                                    sx={{ marginTop: "1rem" }}
                                    onClick={() => navigate("../login")}
                                >
                                    Voltar para o login
                                </Button>
                            </Box>
                        </>
                        
                    </Box>
                </CardContainer>

           </Box>
        </>
   );
}
