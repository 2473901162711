import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React, { useEffect, useState, useContext } from "react";
import { useAuthContext } from "../../contexts/Auth/AuthProvider";
import { useNavigate } from "react-router-dom";
import { FeedbackContext } from "../../contexts/FeedBack/FeedbackContext";
import ServerResponseContext from "../../contexts/ServerResponse/ServerResponseContext";
import api from "../../services/api";

export default function UnifiList() {
    const [unifi, setUnifi] = useState([]);
    const { getApiToken } = useAuthContext();
    const navigate = useNavigate();
    const { setFeedbackMessage } = useContext(FeedbackContext);


    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        async function fetchData() {
            await fetchUnifi();
        }
        fetchData();
    }, []);

    
    async function fetchUnifi() {
        Promise.all([
            api.get("/auditunifi", {
                headers: {
                    Authorization: getApiToken(),
                },
            }),
        ])
            .then(function ([reslan]) {
                setUnifi(reslan.data);
            })
            .catch ((error) => {
                const attempt = ServerResponseContext(error)
                if (attempt.notify){
                    navigate(`/Notificado`, {state: { message: attempt.message}})
                } else {
                    setFeedbackMessage({ type: "error", message: attempt.message });
                }
            });
    }


    return (
        <>
            {unifi ? (
                <TableContainer sx={{ border: "1px solid #dad9d9", borderRadius: "5px" }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        id
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Data de Criação
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Ação
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Local
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Observação
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography
                                        color='primary'
                                        fontSize='1.1rem'
                                    >
                                        Função Responsável?
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {unifi.map((unifi) => (
                                <TableRow key={unifi.id}>
                                    <TableCell>{unifi.id}</TableCell>
                                    <TableCell>{unifi.created_on}</TableCell>
                                    <TableCell>{unifi.db_action}</TableCell>
                                    <TableCell>{unifi.location}</TableCell>
                                    <TableCell>{unifi.obs}</TableCell>
                                    <TableCell>{unifi.code}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                "Nenhum dado de auditoria registrado"
            )}
        </>
    );
}
