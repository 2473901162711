import React from "react";

import CardContainer from "../components/CardContainer";
import { Box, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function Welcome() {

    return (
        <>
            <Grid container justifyContent='center' mt='2rem'>
                <Grid item xs={10} md={10}>
                    <CardContainer>
                        <Box display='flex' flexDirection='column'>
                            <Typography variant='h4' textAlign='center'>
                                Autorização de uso de dados Pessoais
                            </Typography>
                            <Typography variant='h6' textAlign='center'>
                                HOTSPOT Vila A Inteligente
                            </Typography>
                            <br></br>
                            <Typography variant='body' textAlign='center'>
                                Primeiramente, você só pode aderir aos Termos de Uso e Política de Privacidade para usufruir deste serviço, se for maior de 18 anos de idade.
                            </Typography>
                            <Typography variant='body' textAlign='left'>
                                <p>
                                    Para uso do serviço de Wi-Fí público por
                                    meio do HOTSPOT Vila A Inteligente, serão
                                    coletadas e armazenadas as seguintes
                                    informações pessoais ao aderir o uso do
                                    serviço:
                                </p>
                                <ul>
                                    <li>Facebook Profile;</li>
                                    <li>E-mail;</li>
                                    <li>Nome do dispositivo conectado;</li>
                                    <li>Endereço MAC do dispositivo;</li>
                                    <li>Endereço IP atribuído pela rede ao dispositivo.</li>
                                    
                                </ul>

                            </Typography>
                            <Typography variant='body' textAlign='center'>
                                A partir da sua aprovação, o serviço acima
                                mencionado e a Fundação PTI-BR utilizarão as
                                informações listadas acima, conforme detalhado
                                no <Link to={"../TermosDeUso"} target="_blank" >Termos de Uso</Link> e a{" "}
                                <Link to={"../PoliticaDePrivacidade"} target="_blank">Política de Privacidade</Link> do HOTSPOT
                                Vila A Inteligente, respeitando o disposto pela
                                Lei Geral de Proteção de Dados Pessoais (n°
                                13.709).
                            </Typography>
                            
                        </Box>
                    </CardContainer>
                </Grid>
            </Grid>
        </>
    );
}
