// AuditUnifi.js
import { Grid, Typography } from "@mui/material";
import CardContainer from "../components/CardContainer";
import SystemList from "../components/ListSystem";

export default function AuditSystem() {
    return (
        <>
            <CardContainer hideIcon>
                <Grid container justifyContent='space-between' mb='2rem'>
                    <Grid item xs={12} md={8} mb='2rem'>
                        <Typography variant='h4' fontWeight='bold'>
                            Dados de Auditoria deste Sistema
                        </Typography>
                        
                    </Grid>
                    <Typography variant='body' textAlign='left'>
                                <p>
                                    Este sistema armazena logs de ações como, criar / editar / excluir qualquer informação,
                                    seja ela através do sistema ou diretamente no banco de dados.
                                </p>
                                <p>
                                    Você pode ver o log de qualquer uma dessas ações nesta tela.
                                </p>
                                <p>
                                    Quando o campo SYS USER for "None" significa que foi uma ação executada diretamente no Banco de Dados.
                                </p>
                                <p>
                                    Quando a ação de criar / editar / excluir for executada na tabela managers, a coluna "Dados" conterá apenas o identificador
                                    do usuário modificado, as demais informações foram omitidas para estar em concordância com a LGPD.
                                </p>
                                <p>
                                    Você não encontrará logs da controladora aqui, para mais informações acesse o menu Unifi.
                                </p>
                        </Typography>
                </Grid>
                <SystemList />
            </CardContainer>
        </>
    );
}
